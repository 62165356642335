// TextModal.tsx
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanSelectedElement,
  setReload,
  setShowFalse,
  updateData,
  updateFont,
  updateHide,
  updateIsLink,
  updateLink,
  updateShowSelectedElement,
  updateTextColor,
} from "../../../store/actions/dataSlice";
import "./styles-modal.css";
import { renderTypeComponent } from "../../../helpers/helpers";
import { API_URL } from "../../../helpers/api";
import { useToast } from "../Toast/ToastContext";
import { useLoading } from "../Loading/LoadingContext";


const Modal: React.FC<any> = ({ element, data, children, color, image, hideElement, isLink, link, font }) => {
  const dispatch = useDispatch();
  const addToast = useToast();

  const { showLoading, hideLoading } = useLoading();



  const idSection = useSelector((state: { content: any }) => state.content.selectedSection);
  const showModal = useSelector((state: { content: any }) => state.content.show);
  const id = element.id


  const closeModal = () => {
    dispatch(setShowFalse());
    dispatch(cleanSelectedElement());
  };

  const handleSubmit = () => {
    showLoading();


    if (element.type === 'TEXT'){
      saveText()
      hideLoading()
    }
    else if (element.type === 'IMAGE') {
      dispatch(updateHide({ id, idSection, hideElement }));
      saveImage(image);
    } else if (element.type === 'SECTION') {
      dispatch(updateData({ id, idSection, data, addToast }));
      hideLoading();     
    }
    dispatch(setReload());
    closeModal();
  };

  const saveImage = async (formData: FormData)  => {
      // Enviar la imagen a la API en Flask
      try {
        const response = await fetch(`${API_URL}uploadImage`, {
            method: 'POST',
            body: formData
        });
        if (response.ok) {
          const newImage = await response.json();
          dispatch(updateData({ id: element.id, idSection, data: newImage, addToast }));
          dispatch(setReload());
          hideLoading();
        } else {
          hideLoading();
          addToast('Error al guardar la imagen', 'error');
        }
      } catch (error) {
        hideLoading();
        addToast('Error al guardar la imagen', 'error');
      }
  }

  const saveText = async () => {
    try {
      dispatch(updateTextColor({ id, idSection, color }));
      dispatch(updateIsLink({ id, idSection, isLink }));
      dispatch(updateLink({ id, idSection, link }));
      dispatch(updateHide({ id, idSection, hideElement }));
      // await dispatch(updateFont({ id, idSection, font }));
      dispatch(updateData({ id, idSection, data, addToast }));
      hideLoading()

    } catch (error) {
      // Manejo de errores si alguna acción asíncrona falla
      console.error(error);
      hideLoading()
    }
  };
  
  

  return (
      <div className={`modal ${showModal ? 'show-modal' : ''}`}>
        <div className="header-modal">
          <h2>Editar {renderTypeComponent(element)}</h2>
          <i className="fa-regular fa-rectangle-xmark close-modal" onClick={closeModal}></i>
        </div>
        <div className="body-modal">{children}</div>
        <div className="footer-modal">
          <button onClick={handleSubmit}>Guardar</button>
        </div>
      </div>

  );
};

export default Modal;
