import { useEffect, useState } from 'react';
import axios from 'axios';
import '../Login/login.css';
import { API_URL } from '../../helpers/api';
import { useToast } from '../../components/Native/Toast/ToastContext';

const DeleteUser = () => {
    const addToast = useToast();

    const [password, setPassword] = useState('');
    const [users, setUsers] = useState<{ user: string; url: string }[]>([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [accessGranted, setAccessGranted] = useState(false); // Estado para verificar si la contraseña es correcta

    // Contraseña correcta para acceder
    const correctPassword = 'bobadilla'; // Cambia esto por una contraseña real o lógica de verificación

    // Obtener la lista de usuarios al cargar el componente
    useEffect(() => {
        axios
            .get(`${API_URL}users`)
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                addToast('Error al obtener la lista de usuarios', 'error');
            });
    }, []);

    const handlePasswordChange = (e: any) => {
        setPassword(e.target.value);
    };

    const handlePasswordSubmit = (e: any) => {
        e.preventDefault();
        if (password === correctPassword) {
            setAccessGranted(true); // Si la contraseña es correcta, mostrar el contenido
        } else {
            addToast('Contraseña incorrecta', 'error'); // Si la contraseña es incorrecta
        }
    };

    const handleDelete = (e: any) => {
        e.preventDefault();
        if (!selectedUser) {
            addToast('Por favor, seleccione un usuario para eliminar', 'warning');
            return;
        }

        axios
            .delete(`${API_URL}deleteUser`, {
                data: { username: selectedUser }, // Se envía el nombre de usuario en el cuerpo
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                addToast(`Usuario eliminado: ${selectedUser}`, 'success');
                // Actualizar la lista de usuarios tras eliminar uno
                setUsers(users.filter((u) => u.user !== selectedUser));
            })
            .catch((error) => {
                let errorMessage = 'Error desconocido';
                if (error.response) {
                    errorMessage = error.response.data.message;
                } else if (error.request) {
                    errorMessage = 'No se pudo conectar al servidor';
                } else {
                    errorMessage = error.message;
                }
                addToast(`Error: ${errorMessage}`, 'error');
            });
    };

    // Si el acceso no ha sido concedido, mostramos el input de contraseña
    if (!accessGranted) {
        return (
            <div className="body-login">
                <div className="login-form-container">
                    <h1 className="login-form-title">Acceso restringido</h1>
                    <form onSubmit={handlePasswordSubmit}>
                        <input
                            type="password"
                            placeholder="Introduce la contraseña"
                            value={password}
                            onChange={handlePasswordChange}
                            className="login-form-input"
                        />
                        <input
                            type="submit"
                            value="Entrar"
                            className="login-form-button"
                        />
                    </form>
                </div>
            </div>
        );
    }

    // Si la contraseña es correcta, mostramos el formulario de eliminar usuario
    return (
        <div className="body-login">
            <div className="login-form-container">
                <h1 className="login-form-title">Eliminar Usuario</h1>
                <form onSubmit={handleDelete}>
                    <select
                        onChange={(e) => setSelectedUser(e.target.value)}
                        className="login-form-input"
                    >
                        <option value="">Seleccione un usuario</option>
                        {users.map((user) => (
                            <option key={user.user} value={user.user}>
                                {user.user} - {user.url}
                            </option>
                        ))}
                    </select>
                    <input
                        type="submit"
                        value="Eliminar Usuario"
                        className="login-form-button"
                    />
                </form>
            </div>
        </div>
    );
};

export default DeleteUser;
