import { useEffect, useState } from "react";
import axios from 'axios';
import './invitations.css';
import { API_URL } from "../../helpers/api";
import { sanitizeInput } from "../../helpers/helpers";
import { useToast } from "../../components/Native/Toast/ToastContext";

const Invitations = () => {

    const addToast = useToast();

    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showform, setShowForm] = useState(false);
    const [showmsg, setShowMsg] = useState(false);
    const [showModalMobile, setShowModalMobile] = useState(false);


    const [id, setId] = useState('');
    const [oldId, setOldId] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [adulto, setAdulto] = useState('');
    const [nino, setNino] = useState('');
    const [objIndividual, setObjIndividual] = useState({
        "adulto": "",
        "adultoconf": "",
        "apellidos": "",
        "confirmed": 0,
        "idboda": "",
        "idinvite": "",
        "mensaje": "",
        "mensajenovios": null,
        "nino": "",
        "ninoconf": "",
        "nombre": ""
    });

    const [initialValues, setInitialValues] = useState({});
    const [countAdultoConf, setCountAdultoConf] = useState(0);
    const [countNinoConf, setCountNinoConf] = useState(0);
    const [countTotalConfirmados, setCountTotalConfirmados] = useState(0);
    const [countTotal, setCountTotal] = useState(0);
    const [filteredData, setFilteredData] = useState([]);

      // Cálculo de “sin confirmar”
  const sinConfirmar = countTotal - countTotalConfirmados;

    const cardsInfo = [
        {
          id: 'adultoconf',
          title: 'Adultos confirmados',
          value: countAdultoConf,
          icon: 'fa-solid fa-user-check',
        },
        {
          id: 'ninoconf',
          title: 'Niños confirmados',
          value: countNinoConf,
          icon: 'fa-solid fa-child',
        },
        {
          id: 'confirmed',
          title: 'Invitados que completaron el formulario',
          value: countTotalConfirmados,
          icon: 'fa-solid fa-clipboard-check',
        },
        {
          id: 'total',
          title: 'Total invitados',
          value: countTotal,
          icon: 'fa-solid fa-users',
        },
        {
          id: 'not confirmed',
          title: 'Sin confirmar',
          value: sinConfirmar,
          icon: 'fa-solid fa-user-clock',
        },
      ];


    // 0 if for editing and 1 is for new invite
    const [isEdit, setIsEdit] = useState(0);

    const closeEdit = () => {
        cleanForm();
        setShowForm(false);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        let adultoNumber: any = parseFloat(adulto);
        let ninoNumber: any = parseFloat(nino);

        const adultoCheck = !isNaN(adultoNumber) && isFinite(adultoNumber) && /^[0-9]+(\.[0-9]+)?$/.test(adulto);
        const ninoCheck = !isNaN(ninoNumber) && isFinite(ninoNumber) && /^[0-9]+(\.[0-9]+)?$/.test(nino);
        
        if (!adultoCheck  || !ninoCheck  ) {
            addToast('Asegurate de usar solo números al definir los pases.', 'warning');
            return;
        }

        const localStorage = window.localStorage;
        const idBoda = localStorage.getItem('idBoda');
        let rawData: any = { id, nombre, apellidos, mensaje, adulto, nino};

        if (isEdit === 1) {
            // Check if the id already exists in the invitations
            const existingInvite = data.find((invite: any) => invite.idinvite === id);
            if (existingInvite) {
                addToast('Ya existe una invitación con ese ID', 'warning');
                return;
            }
        }
    
        if (isEdit === 0) {
            if (compareFormValues(rawData)) {
                setShowForm(false)
                return;
            }
            rawData.oldId = oldId;
        }
    
        let dataToSend = JSON.stringify({...rawData, idBoda});
        dataToSend = sanitizeInput(dataToSend);
        const url = isEdit === 0 ? `${API_URL}updateInvitation` : `${API_URL}newInvitation`;
    
        try {
            await axios.post(url, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            getData();
            cleanForm();
            setShowForm(false);
            addToast('Invitacion guardada', 'success');

        } catch (error) {
            addToast('Error al guardar la invitacion', 'error');
        }
    };
    

    const compareFormValues = (currentValues: any) => {
        return JSON.stringify(currentValues) === JSON.stringify(initialValues);
    };

    const countConfirmed = (data: any) => {
        let countAdulto = data.reduce((total: any, item: any) => {
            return total + Number(item.adultoconf);
        }, 0);

        let countNino = data.reduce((total: any, item: any) => {
            return total + Number(item.ninoconf);
        }, 0);

        let countTotalConfirmados = data.reduce((count: number, item: any) => {
            // Verifica si la propiedad 'confirmed' es igual a 1 y suma al conteo
            return count + (item.confirmed === 1 ? 1 : 0);
        }, 0);

        let countTotal = data.length;

        setCountAdultoConf(countAdulto);
        setCountNinoConf(countNino);
        setCountTotalConfirmados(countTotalConfirmados)
        setCountTotal(countTotal)
    };

    const getData = async () => {
        try {
            const localStorage = window.localStorage;
            const idBoda = localStorage.getItem("idBoda");
            const res = await axios.get(`${API_URL}getInvitations`, {
                params: { idBoda: idBoda }
            });
            if (res.status === 204) {
                console.log('no hay solicitudes');
            } else {
                setData(res.data);
                setFilteredData(res.data)
                countConfirmed(res.data);
            }
        } catch (err: any) {
            if (err.response) {
                addToast('Error al obtener las invitaciones', 'error');
            }
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handleShowMsg = (row: any) => {
        setNombre(row.nombre);
        setApellidos(row.apellidos);
        setMensaje(row.mensajenovios);
        setShowMsg(true);
    }

    const handleShowModalMobile = (row: any) => {
        setObjIndividual(row)
        setShowModalMobile(true);
    }

    const handleRowClick = (row: any) => {
        setShowModalMobile(false)
        setIsEdit(0)
        setShowForm(true);
        setOldId(row.idinvite);
        setId(row.idinvite);
        setNombre(row.nombre);
        setApellidos(row.apellidos);
        setMensaje(row.mensaje);
        setAdulto(row.adulto);
        setNino(row.nino);

        setInitialValues({
            id: row.idinvite,
            nombre: row.nombre,
            apellidos: row.apellidos,
            mensaje: row.mensaje,
            adulto: row.adulto,
            nino: row.nino
        });
    };

    const handleDeleteClick = (row: any) => {
        if (!window.confirm(`Seguro que deseas eliminar la invitación de ${row.nombre} ${row.apellidos}  ? `)){
            return;
        }
        const idBoda = window.localStorage.getItem("idBoda");
        let rawData: any = { id: row.idinvite ,idBoda };

        const data = JSON.stringify(rawData);
        const url = `${API_URL}deleteInvitation`
    
        axios.post(url, data, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(() => {
                getData();
                cleanForm();
                setShowForm(false);
                setShowModalMobile(false);
                addToast('Invitacion eliminada', 'success');
            })
            .catch((error) => {
                addToast('Error al eliminar la invitacion', 'error');
            });
    }

    const cleanForm = () => {
        setOldId('');
        setId('');
        setNombre('');
        setApellidos('');
        setMensaje('');
        setAdulto('');
        setNino('');
    };

    const handleSearch = (e: any) => {
        setSearchTerm(e.target.value);

       let filteredData = data.filter((row: any) => {
        return row.nombre.toLowerCase().includes(e.target.value.toLowerCase());
       });
       setFilteredData( filteredData )

    };

    const handleNewInvite = () => {
        setIsEdit(1)
        cleanForm()
        setShowForm(true);
    }

    const handleFilter = (filter: string) => {

        let filteredData: any = []

        if (filter === 'adultoconf' || filter === 'ninoconf' ){
            filteredData = data.filter((row: any) => {
                // Convierte el valor en row[filter] a un número y verifica si es mayor que 0
                return parseFloat(row[filter]) > 0;
            });
        } else if (filter === 'confirmed') {
            filteredData = data.filter((row: any) => {
                // Convierte el valor en row[filter] a un número y verifica si es mayor que 0
                return parseFloat(row['confirmed']) > 0;
            });       
        } else if (filter === 'total') {
            filteredData = data.filter((row: any) => {
                // Convierte el valor en row[filter] a un número y verifica si es mayor que 0
                return row;
            }); 
        } else if (filter === 'not confirmed') {
            filteredData = data.filter((row: any) => {
                return parseFloat(row['confirmed']) == 0;
            }); 
        }
           setFilteredData( filteredData )
    }

    return (
        <>
            <div className="wrapper">
                <div className="stats-cards-container">
                    {cardsInfo.map((card) => (
                        <div
                        key={card.id}
                        className="stats-card"
                        onClick={() => handleFilter(card.id)}
                        >
                        {/* Ícono opcional (puedes cambiarlo por un svg o imagen) */}
                        <div className="stats-card-icon">
                            <i className={card.icon} />
                        </div>
                        <div className="stats-card-content">
                            <p className="stats-card-title">{card.title}</p>
                            <p className="stats-card-value">{card.value}</p>
                        </div>
                        </div>
                    ))}
                </div>


                <div className="search-invite-container">
                    <input
                        type="text"
                        placeholder="Buscar por nombre"
                        value={searchTerm}
                        onChange={handleSearch}
                        className="search-bar"
                    />
                    <button className="invite-add-button" onClick={handleNewInvite}>
                        <i className="fa-regular fa-plus"></i> Nueva Invitacion
                    </button>
                </div>

                {filteredData.length > 0 ? (
                    <div className="wrapper-datos-invites">
                        <table className="modern-table">
                        <thead>
                            <tr>
                            <th>#ID</th>
                            <th>Nombre</th>
                            <th>Apellidos</th>
                            <th>Mensaje</th>
                            <th>Pases Niño</th>
                            <th>Pases Adulto</th>
                            <th>Adulto/s confirmados</th>
                            <th>Niños/s confirmados</th>
                            <th>Ver Mensaje</th>
                            <th>¿Ya confirmó?</th>
                            <th>Editar</th>
                            <th>Borrar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((row: any, index) => (
                            <tr key={index}>
                                <td>{row.idinvite}</td>
                                <td>{row.nombre}</td>
                                <td>{row.apellidos}</td>
                                <td className="mensaje-col">{row.mensaje}</td>
                                <td>{row.nino}</td>
                                <td>{row.adulto}</td>
                                <td>{row.adultoconf}</td>
                                <td>{row.ninoconf}</td>
                                <td>
                                <i
                                    onClick={() => handleShowMsg(row)}
                                    className="fa-solid fa-message icon-action"
                                    title="Ver Mensaje"
                                />
                                </td>
                                <td>
                                {row.confirmed === 1 ? (
                                    <i
                                    className="fa-solid fa-check icon-confirmed"
                                    title="Confirmado"
                                    />
                                ) : (
                                    <i className="fa-solid fa-times icon-not-confirmed" title="No confirmado" />
                                )}
                                </td>
                                <td>
                                <i
                                    onClick={() => handleRowClick(row)}
                                    className="fa-solid fa-pen-to-square icon-action"
                                    title="Editar"
                                />
                                </td>
                                <td>
                                <i
                                    onClick={() => handleDeleteClick(row)}
                                    className="fa-solid fa-trash-can icon-delete"
                                    title="Borrar"
                                />
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                        <div className="invites-registros">
                        {filteredData.map((row: any, index) => (
                                    <div className="invite-registro"  onClick={() => handleShowModalMobile(row)} >
                                            <div className={row.confirmed ? 'data-registro-border data-registro-border-green' : 'data-registro-border  data-registro-border-red'}></div>
                                            <b className="data-registro-name"><i className="fa-solid fa-envelope"></i> {row.nombre} {row.apellidos}</b>
                                    </div>
                                ))}
                        </div>

                    </div>

                ) : (
                    <div className="invites-emptystate">
                        <p>No hay invitaciones.</p>
                    </div>
                )}

                {showform && 
                <div className="invite-modal-wrapper invite-modal-wrapper">
                    <form className="invite-modal" onSubmit={handleSubmit}>
                        <div className="invite-modal-header"> 
                            <h2 className="invite-modal_msg_title">{isEdit === 0 ? "Editar invitación" : "Nueva invitación"}</h2>
                            <i onClick={closeEdit} className="fa-solid fa-xmark"></i>                           
                        </div>
                    

                    <div className="invites-form-group">
                        <label htmlFor="invite-id">Código de invitado:</label>
                        <input
                        id="invite-id"
                        type="text"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        />
                    </div>

                    <div className="invites-form-group">
                        <label htmlFor="invite-nombre">Nombre:</label>
                        <input
                        id="invite-nombre"
                        type="text"
                        value={nombre}
                        onChange={(e) => setNombre(e.target.value)}
                        />
                    </div>

                    <div className="invites-form-group">
                        <label htmlFor="invite-apellidos">Apellidos:</label>
                        <input
                        id="invite-apellidos"
                        type="text"
                        value={apellidos}
                        onChange={(e) => setApellidos(e.target.value)}
                        />
                    </div>

                    <div className="invites-form-group">
                        <label htmlFor="invite-mensaje">Mensaje:</label>
                        <input
                        id="invite-mensaje"
                        type="text"
                        value={mensaje}
                        onChange={(e) => setMensaje(e.target.value)}
                        />
                    </div>

                    <div className="invites-form-group">
                        <label htmlFor="invite-adulto">Pases adulto:</label>
                        <input
                        id="invite-adulto"
                        type="text"
                        value={adulto}
                        onChange={(e) => setAdulto(e.target.value)}
                        />
                    </div>

                    <div className="invites-form-group">
                        <label htmlFor="invite-nino">Pases niño:</label>
                        <input
                        id="invite-nino"
                        type="text"
                        value={nino}
                        onChange={(e) => setNino(e.target.value)}
                        />
                    </div>

                    <button className="submit-button-invites" type="submit">
                        {isEdit === 0 ? "Guardar cambios" : "Guardar nueva invitación"}
                    </button>

                    </form>
                </div>
                }

                {showmsg && 
                    <div className="invite-modal-wrapper">
                        <div className="invite-modal invite-modal__msg">
                            <p className="invite-modal_msg_title"> {nombre} {apellidos}: </p>
                            <p className="invite-modal_msg_description"> {mensaje} </p>
                            <p onClick={(e) => setShowMsg(false)} className="invite-cancel-button">Cerrar</p>
                        </div>
                    </div>
                }

                {showModalMobile && 
                    <div className="invite-modal-wrapper">
                        <div className="invite-modal invite-modal__msg">
                            <div className="invite-modal-header">
                                <p className="invite-modal_msg_title"><i className="fa-solid fa-user invite-modal-header-person-icon"></i> {objIndividual.nombre} {objIndividual.apellidos} </p>
                                <i onClick={(e) => setShowModalMobile(false)} className="fa-solid fa-xmark"></i>
                            </div>
                            <div className="invite-modal-body">
                                <div className="invite-modal-body-box">
                                    <p className="invite-modal-body-box__bold">ID: </p>
                                    <p className="invite-modal-body-box__normal">{objIndividual.idinvite}</p>
                                </div>
                                <div className="invite-modal-body-box">
                                    <p className="invite-modal-body-box__bold">Pases adulto: </p>
                                    <p className="invite-modal-body-box__normal">{objIndividual.adulto}</p>
                                </div>
                                <div className="invite-modal-body-box">
                                    <p className="invite-modal-body-box__bold">Pases Niño: </p>
                                    <p className="invite-modal-body-box__normal"> {objIndividual.nino}</p>
                                </div>
                                {objIndividual.confirmed ? (
                                    <> 
                                        <div className="invite-modal-body-box">
                                                <p className="invite-modal-body-box__bold">Adulto/s confirmados: </p>
                                                <p className="invite-modal-body-box__normal"> {objIndividual.adultoconf}</p>
                                        </div>
                                        <div className="invite-modal-body-box">
                                                <p className="invite-modal-body-box__bold">Niños/s confirmados: </p>
                                                <p className="invite-modal-body-box__normal"> {objIndividual.ninoconf}</p>
                                        </div>  
                                        <div className="invite-modal-body-box">
                                                <p className="invite-modal-body-box__bold">Mensaje para los novios: </p>
                                                <p className="invite-modal-body-box__normal"> {objIndividual.mensajenovios}</p>
                                        </div>                          
                                    </>
                                ) : (
                                    <p>Aún no ha confirmado.</p>
                                )}
                            </div>


                            <p onClick={(e) => handleRowClick(objIndividual)} className="invite-edit-button">Editar</p>
                            <p onClick={(e) => handleDeleteClick(objIndividual)} className="invite-cancel-button">Eliminar</p>

                        </div>
                    </div>
                }

            </div>
        </>
    );
}

export default Invitations;


