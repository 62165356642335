// useDomainInfo.ts (o .js)
import { IS_LOCAL } from "../helpers/api";

export function useDomainInfo() {
  const hostname = window.location.hostname; 
  const parts = hostname.split('.');
  
  // Ejemplo: si parts = ["admin", "miboda", "xyz"], 
  // domain = "mibodamx.cloud", subdomain = "admin"
  let domain = hostname;
  let subdomain = '';
  
  if (parts.length > 2) {
    subdomain = parts[0];
    domain = parts.slice(-2).join('.');
  }

  // Definir si es local
  const isLocal = (IS_LOCAL as any).toLowerCase() === "true";
  
  // Lógica de isAdmin e isSuperAdmin
  // Ejemplo, si domain === 'weddingscloud.fun' => superadmin
  let isSuperAdmin = (domain === 'weddingscloud.fun') || false;
  let isAdmin = (subdomain === 'admin') || false;

  if (isLocal) {
    // Sobreescribir por conveniencia en local
    isAdmin = true;
    isSuperAdmin = true;
  }

  return {
    domain,
    subdomain,
    isAdmin,
    isSuperAdmin,
    isLocal
  };
}
