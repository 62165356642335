import './templates-styles.css';


const TemplatesWeddings = ({ onClickTemplate, onPreviewTemplate }: any) => {



    return ( 
        <>
            <div className="template">
                <div className="background-image">
                    <img src="./1.png" alt="" />
                </div>
                <div className="template-data">
                    <button className='template-data-item btn-preview' onClick={() => onPreviewTemplate(1)}>Ver diseño</button>
                    <button className='template-data-item btn-replace' onClick={() => onClickTemplate(1)}>Reemplazar diseño</button>
                </div>
            </div>
            <div className="template">
                <div className="background-image">
                    <img src="./2.png" alt="" />
                </div>
                <div className="template-data">
                    <button className='template-data-item btn-preview' onClick={() => onPreviewTemplate(2)}>Ver diseño</button>
                    <button className='template-data-item btn-replace' onClick={() => onClickTemplate(2)}>Reemplazar diseño</button>
                </div>
            </div>

            <div className="template">
                <div className="background-image">
                    <img src="./3.png" alt="" />
                </div>
                <div className="template-data">
                    <button className='template-data-item btn-preview' onClick={() => onPreviewTemplate(3)}>Ver diseño</button>
                    <button className='template-data-item btn-replace' onClick={() => onClickTemplate(3)}>Reemplazar diseño</button>
                </div>
            </div>

            <div className="template">
                <div className="background-image">
                    <img src="./4.png" alt="" />
                </div>
                <div className="template-data">
                    <button className='template-data-item btn-preview' onClick={() => onPreviewTemplate(4)}>Ver diseño</button>
                    <button className='template-data-item btn-replace' onClick={() => onClickTemplate(4)}>Reemplazar diseño</button>
                </div>
            </div>
        </>    
     );
}
 
export default TemplatesWeddings;