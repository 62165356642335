// TextModal.tsx
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ModalProps } from "../../../store/actions/dataSlice";
import Modal from "../../Native/Modal/Modal";
import ColorPicker from "../../Native/ColorPicker/ColorPicker";
import './textmodal.css';
import FontSelector from "../../Native/FontSelector/FontSelector";

const TextModal: React.FC<ModalProps> = ({ element }) => {
  const selectedElement = useSelector((state: { content: any }) => state.content.selectedElement);


  const [data, setData] = useState<string | undefined>(selectedElement.data);
  const [color, setColor] = useState<string | undefined>(selectedElement.color);
  const [hide, setChecked] = React.useState<boolean>(selectedElement.hide === "true");
  const [isLink, setIsLink] = React.useState<boolean>(selectedElement.isLink === "true");
  const [link, setLinkUrl] = React.useState<string | undefined>(selectedElement.link || "");

  const [selectedFont, setSelectedFont] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setData(selectedElement.data);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [selectedElement.data]);

  const handleText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(e.target.value);
  };

  const handleColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColor(e.target.value);
  };

  const handleHideChange = () => {
    setChecked((prevHide) => !prevHide);
  };

  const handleIsLinkChange = () => {
    setIsLink((prevIsLink) => !prevIsLink);
  };

  const handleLinkUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkUrl(e.target.value);
  };

  const handleFontSelect = (font: string) => {
    setSelectedFont(font); // Actualizar el estado con la fuente seleccionada
    console.log(`Fuente seleccionada: ${font}`);
  };

  return (
    <Modal data={data} element={element} color={color} hideElement={hide} isLink={isLink} link={link} font={selectedFont}>
      <input type="text" ref={inputRef} value={data} onChange={handleText} style={{ fontFamily: selectedFont }} />
      { /* <FontSelector onFontSelect={handleFontSelect} /> */ }

      <ColorPicker value={color} onChange={handleColor} />
      <div className="checkbox-container">
        <input
          type="checkbox"
          className="checkbox-input"
          checked={hide}
          onChange={handleHideChange}
        />
        <label className="checkbox-label">Ocultar elemento</label>
      </div>
      <div className="checkbox-container">
        <input
          type="checkbox"
          className="checkbox-input"
          checked={isLink}
          onChange={handleIsLinkChange}
        />
        <label className="checkbox-label">Convertir a link</label>
      </div>
      {isLink && (
        <div className="link-input-container">
          <input
            type="text"
            placeholder="Ingresa el link"
            value={link}
            onChange={handleLinkUrlChange}
          />
        </div>
      )}
    </Modal>
  );
};

export default TextModal;