import "./builder.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from "./Pages/Builder/Home/Home";
import { Viewer } from "./Pages/Viewer/Viewer";
import Login from "./Pages/Login/login";
import PrivateRoutes from "./helpers/PrivateRoutes";
import Builder from "./Pages/Builder/Builder";
import Templates from "./Pages/Templates/Templates";
import Invitations from "./Pages/Invitations/Invitations";
import { Site } from "./Pages/Site/Site";
import Bum from "./Pages/Bum/Bum";
import { TemplateRenderer } from "./Pages/TemplateRenderer/TemplateRenderer";
import Planner from "./Pages/Planner/Planner";
import { Tables } from "./Pages/Tables/Tables";
import Cbum from "./Pages/Cbum/cbum";
import Profile from "./Pages/Profile/Profile";
import Template5 from "./components/Templates/Template5/template5_raw";
import { useDomainInfo } from "./helpers/useDomainInfo";
import SuccessPayment from "./helpers/successPayment";
import Register from "./Pages/Register/Register";
import HomePage from "./Pages/Homepage/Homepage";
import TemplatesHomepage from "./Pages/TemplatesHomepage/TemplatesHomepage";

function App() {
  const {
    isAdmin,
    isSuperAdmin,
  } = useDomainInfo();   // Extraemos la lógica

  return (
    <Router>
      <Routes>
        {isAdmin ? (
          <>
            {/* Rutas Privadas */}
            <Route element={<PrivateRoutes />}>
              <Route path="/home" element={<Home />} />
              <Route path="/builder" element={<Builder />} />
              <Route path="/viewer" element={<Viewer />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/templates" element={<Templates />} />
              <Route path="/invitations" element={<Invitations />} />
              <Route path="/planner" element={<Planner />} />
              <Route path="/tables" element={<Tables />} />
              <Route path="/successPayment" element={<SuccessPayment />} />
              <Route path="/template" element={<Template5 />} />

              <Route path="*" element={<Home />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/templateRenderer" element={<TemplateRenderer public={false} />} />
            <Route path="/templateshome" element={<TemplatesHomepage />} />

            {/* Rutas super admin */}
            <Route path="/bum" element={
              isSuperAdmin ? <Bum /> : <Navigate to="/home" replace />
            } />
            <Route path="/cbum" element={
              isSuperAdmin ? <Cbum /> : <Navigate to="/home" replace />
            } />
          </>
        ) : (
          <>
            {/* Rutas para site.com */}
            {/* Rutas Públicas */}
            <Route path="/" element={<HomePage />} />
            <Route path="/site" element={<Site />} />
            <Route path="/templateshome" element={<TemplatesHomepage />} />
            <Route path="/template" element={<TemplateRenderer public={true} />} />
            <Route path="*" element={<Site />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
