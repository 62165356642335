import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./SuccessPayment.css";
import { API_URL } from "./api";

const SuccessPayment = () => {
  const query = new URLSearchParams(useLocation().search);
  const idBoda = localStorage.getItem("idBoda");

  // Parámetros de la query string
  const checkout_id = query.get("checkout_id");
  const order_id = query.get("order_id");
  const payment_status = query.get("payment_status");

  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    // Si no existe idBoda en localStorage, no se puede proceder
    if (!idBoda) {
      setError("No se encontró un idBoda en localStorage.");
      return;
    }

    let isMounted = true; // Para evitar actualizar el estado si el componente se desmonta antes

    const validateOrder = async () => {
      try {
        // 1. Obtener el order_id asociado al idBoda
        const orderResp = await fetch(`${API_URL}/getOrder?idBoda=${idBoda}`);
        if (!orderResp.ok) {
          throw new Error("Error al validar el order_id.");
        }
        const orderData = await orderResp.json();

        // Verificar que el order_id devuelto coincida con el recibido en la URL
        if (orderData.order_id !== order_id) {
          throw new Error("Los datos no coinciden con el registrado en el sistema.");
        }

        // 2. Validar el estado de pago (payment_status) con el backend
        const validateResp = await fetch(`${API_URL}/validate_order/${order_id}`);
        if (!validateResp.ok) {
          throw new Error("Error al validar la orden.");
        }
        const validateData = await validateResp.json();

        if (validateData.payment_status !== "paid") {
          throw new Error(validateData.error || "La orden aún no ha sido pagada.");
        }

        // 3. Actualizar el estado del usuario en el backend
        const updateResp = await fetch(`${API_URL}/updateUserStatus/${idBoda}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!updateResp.ok) {
          throw new Error("Error al actualizar el usuario.");
        }

        // Si todo salió bien, marcamos la validación como exitosa
        if (isMounted) {
            localStorage.setItem('turing_mibodamx', '1');
          setIsValid(true);
        }
      } catch (err: any) {
        if (isMounted) {
          setError(err.message || "Hubo un problema al validar la orden. Inténtalo más tarde.");
        }
      }
    };

    validateOrder();

    // Limpieza: para evitar que se intente actualizar el estado tras desmontar
    return () => {
      isMounted = false;
    };
  }, [idBoda, order_id]);

  /**
   * Renderizado de la UI
   */

  // 1. Mostrar error si ocurrió algún problema
  if (error) {
    return (
      <div className="success-payment-container error-state">
        <div className="payment-card error-card">
          <h2 className="payment-title">Error</h2>
          <p className="payment-message">{error}</p>
        </div>
      </div>
    );
  }

  // 2. Mostrar un estado de carga mientras se realiza la validación
  if (!isValid) {
    return (
      <div className="success-payment-container loading-state">
        <div className="payment-card loading-card">
          <img src='logo.png' alt="Logo" className="logo-img" />
          <p className="payment-message">Validando información...</p>
        </div>
      </div>
    );
  }

  // 3. Mostrar contenido si la validación es exitosa
  return (
    <div className="success-payment-container success-state">
      <div className="payment-card success-card">
        <img src='logo.png' alt="Logo" className="logo-img" />
        <h1 className="payment-title">¡Pago Procesado!</h1>
        <div className="payment-details">
          <p className="payment-info">
            <strong>Checkout ID:</strong> {checkout_id}
          </p>
          <p className="payment-info">
            <strong>Order ID:</strong> {order_id}
          </p>
          <p className="payment-info">
            <strong>Estatus del Pago:</strong> {payment_status}
          </p>
        </div>

        {payment_status === "paid" && (
          <p className="payment-status payment-status-paid">
            ¡El pago fue exitoso! Ahora puedes utilizar las funciones premium.
          </p>
        )}
        {payment_status === "pending_payment" && (
          <p className="payment-status payment-status-pending">
            El pago está pendiente. Por favor, verifica más tarde.
          </p>
        )}
        {payment_status === "error" && (
          <p className="payment-status payment-status-error">
            Hubo un error en el procesamiento del pago.
          </p>
        )}
      </div>
    </div>
  );
};

export default SuccessPayment;
