import { Suspense, lazy, useEffect, useState } from "react";
import Template from "../../components/Native/Template/Template";
import "./templaterenderer.css";
import { API_URL } from "../../helpers/api";
import axios from "axios";
import { AppDispatch, fetchContent } from "../../store/actions/dataSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../components/Native/Toast/ToastContext";

export function TemplateRenderer(publicFlag: any) {

  const urlParams = new URLSearchParams(window.location.search);
  const templateId = urlParams.get("idTemplate") || "1";
  let userType = Number(localStorage.getItem('userType') )
  const [templateData, setTemplateData] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const addToast = useToast();

  // useEffect para realizar el POST y obtener los datos del template.
  useEffect(() => {
    const fetchTemplateData = async () => {
      try {
        const response = await fetch(`${API_URL}getTemplateJSON`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ templateId, userType }), // Enviando el templateId en el body.
        });

        if (!response.ok) {
          throw new Error("Error al obtener los datos del template");
        }

        const data = await response.json();
        setTemplateData(data.body.sections[0]);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchTemplateData();
  }, [templateId]); // Vuelve a ejecutar si cambia el templateId.

  // Si no hay datos, muestra un mensaje de carga.
  if (!templateData) {
    return <div>Cargando template...</div>;
  }



  const componentType =  ( templateData as any ).name;
  const idSection =  ( templateData as any ).id;

  const meta = {
    ...( templateData as any).meta,
    idSection: idSection,
  };




  const Component = lazy(() =>
      import(
        `../../components/${userType === 1 ? 'Templates_events' : 'Templates'}/${componentType}/${componentType}`
      )
  );

  const handleReplace = () => {
    if (window.confirm(`¿Estás seguro/a de reemplazar el diseño? Todas las imágenes y textos modificados se perderán.`)) {

      const idBoda = localStorage.getItem('idBoda')

      const obj = JSON.stringify({
        "idBoda": idBoda,
        "idTemplate":templateId,
        "url": "www.bobadilla.com",
        "userType": userType
    });
      axios.post(`${API_URL}updateTemplate`, obj, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    .then(() => {
      dispatch(fetchContent())
      addToast('Diseño cambiado', 'success');
      navigate('/builder');
    })
    .catch(() => {
      addToast('Hubo un error', 'error');
    });
        //dispatch(ReplaceTemplate({ template: templateObj }));W
    }
  }

  return (
    <Suspense key={templateId} fallback={<div>Cargando...</div>}>
      {!publicFlag.public && (
        <div className="template_renderer__back_link">
          <a className="template_renderer__back_link_a" href="/templates">
            Regresar a diseños
          </a>
          <a className="template_renderer__back_link_a" href="#" onClick={handleReplace}>
            Utilizar este diseño
          </a>
        </div>
      )}
      <Template
        meta={meta}
        id={idSection}
        builder={false}
        section={true}
        isTemplatePreview={true}
        templateId={templateId}
      >
        <Component
          meta={meta}
          builder={false}
          isTemplatePreview={true}
          templateId={templateId}
        />
      </Template>
      <div className="template_renderer__unsplash">
        <p>
          <i className="fa-solid fa-circle-info"></i>Todas las imágenes fueron
          obtenidas de:{" "}
          <a href="https://unsplash.com/es" target="_blank" rel="noopener noreferrer">
            https://unsplash.com/es
          </a>
        </p>
      </div>
    </Suspense>
  );
}
