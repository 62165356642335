import { useState } from "react";
import "./TemplatesHomepage.css";

const TemplatesHomepage = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const templates = [
        {
            id: 1,
            image: "template1.png",
            title: "Juan y Karla María",
            date: "24 de Noviembre 2023",
            link: "https://mibodamx.cloud/template?idTemplate=1",
        },
        {
            id: 2,
            image: "template2.png",
            title: "Marcos y Daniela",
            date: "24 de Noviembre 2023",
            link: "https://mibodamx.cloud/template?idTemplate=2",
        },
        {
            id: 3,
            image: "template3.png",
            title: "Juan y Karla",
            date: "Puebla, México\nSalón Bellas Artes",
            link: "https://mibodamx.cloud/template?idTemplate=3",
        },
        {
            id: 4,
            image: "template4.png",
            title: "Ana Y Carlos",
            date: "20 SEP\n2024",
            link: "https://mibodamx.cloud/template?idTemplate=4",
        },
    ];

    return (
        <div className="wedding-templates-container">
        <div className="navbar-homepage-weddings">
            <div className="navbar-homepage-weddings-logo">
                <img
                    src="/transparent_logo.png"
                    alt="logo"
                    width={100}
                    className="navbar-homepage-weddings-logo-img"
                />
            </div>

            <button className="navbar-hamburger" onClick={toggleSidebar}>
                {sidebarOpen ? "✖" : "☰"}
            </button>

            <div className="navbar-homepage-weddings-links">
                <a href="https://mibodamx.cloud" className="navbar-homepage-weddings-link">Inicio</a>
                <a href="https://mibodamx.cloud/templateshome" className="navbar-homepage-weddings-link">Diseños</a>
                <a href="https://admin.mibodamx.cloud" className="navbar-homepage-weddings-link">Iniciar Sesión</a>
                <a href="https://admin.mibodamx.cloud/register" className="navbar-homepage-weddings-link">Registrarme</a>
            </div>

            <div className={`sidebar-homepage-weddings ${sidebarOpen ? "open" : ""}`} id="sidebar">
                <a href="https://mibodamx.cloud" className="sidebar-link">Inicio</a>
                <a href="https://mibodamx.cloud/templateshome" className="sidebar-link">Diseños</a>
                <a href="https://admin.mibodamx.cloud" className="sidebar-link">Iniciar Sesión</a>
                <a href="https://admin.mibodamx.cloud/register" className="sidebar-link">Registrarme</a>
            </div>
        </div>
            <div className="template-container">
                {templates.map((template) => (
                    <div className="template-card" key={template.id}>
                        <img src={template.image} alt={template.title} className="template-image" />
                        <div className="template-info">
                            <h3>{template.title}</h3>
                            <p>{template.date}</p>
                        </div>
                        <a href={template.link} target="_blank" rel="noopener noreferrer" className="view-button">
                            Ver diseño
                        </a>
                    </div>
                ))}
            </div>
            <footer className="site-footer">
                <p>
                    &copy; {new Date().getFullYear()} Todos los derechos reservados | Creado con ❤️ por
                    <a href="https://mibodamx.cloud" target="_blank" rel="noopener noreferrer" className="footer-link">
                        mibodamx.cloud
                    </a>
                </p>
                <p>
                    ¿Quieres la tuya? Síguenos:
                    <a href="https://www.instagram.com/mibodamx.cloud/" target="_blank" rel="noopener noreferrer" className="footer-link social-instagram">
                        Instagram
                    </a>
                </p>
            </footer>
        </div>
    );
};

export default TemplatesHomepage;
