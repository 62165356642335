import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, fetchContent } from '../../store/actions/dataSlice';
import { useEffect, useState } from 'react';
import axios from 'axios';
import './login.css';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../helpers/api';
import { sanitizeInput } from '../../helpers/helpers';
import { useToast } from '../../components/Native/Toast/ToastContext';

const Login = () => {

    const addToast = useToast();


    const  contents  = useSelector((state: any) => state.content.contents);

    const [cookies, setCookie] = useCookies(['token']);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const localStorage = window.localStorage;

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();


    useEffect(() => {
        if (contents.url) {
          navigate('/'); // Cambia '/some-path' por la ruta deseada
        }
      }, [contents]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        let user = sanitizeInput(username)
        let pass = sanitizeInput(password)
        const data = JSON.stringify({username: user, password: pass });
        axios.post(`${API_URL}login`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            let date = new Date();
            let idBoda = response.data.idBoda;
            let idSitio = response.data.idSitio;
            let userType = response.data.userType;
            let sendEmail = response.data.sendEmail;
            let email = response.data.email;
            let id_customer = response.data.id_customer;
            let url = response.data.url;
            let paid = response.data.paid;

            date.setHours(date.getHours() + 8);
            setCookie('token', 'asd', { path: '/', expires: date }); // TODO: add real JWT later
            localStorage.setItem('idBoda', idBoda);
            localStorage.setItem('idSitio', idSitio);
            localStorage.setItem('weddingUser', username);
            localStorage.setItem('userType', userType);
            localStorage.setItem('sendEmail', sendEmail);
            localStorage.setItem('email', email);
            localStorage.setItem('id_customer', id_customer);
            localStorage.setItem('urlmiboda', url);
            localStorage.setItem('turing_mibodamx', paid);


            dispatch(fetchContent());
            //navigate('/'); // Redirect to success page after action

        })
        .catch((error) => {
            let errorMessage = 'Error desconocido'; // Mensaje de error predeterminado
            if (error.response) {
                // Si hay una respuesta de error del servidor
                errorMessage = error.response.data.message || 'Error en la respuesta del servidor';
            } else if (error.request) {
                // Si la solicitud fue realizada pero no se recibió respuesta
                errorMessage = 'No se pudo conectar al servidor';
            } else {
                // Si ocurrió un error durante la configuración de la solicitud
                errorMessage = error.message;
            }
        
            // Mostrar el toast con el mensaje específico del error
            addToast(`Error al iniciar sesión: ${errorMessage}`, 'error');
        });
    };

    return (

        
        <div className="body-login">
            <div className="navbar-homepage-weddings">
                <div className="navbar-homepage-weddings-logo">
                    <img
                        src="/transparent_logo.png"
                        alt="logo"
                        width={100}
                        className="navbar-homepage-weddings-logo-img"
                    />
                </div>

                <button className="navbar-hamburger" onClick={toggleSidebar}>
                    {sidebarOpen ? "✖" : "☰"}
                </button>

                <div className="navbar-homepage-weddings-links">
                    <a href="https://mibodamx.cloud" className="navbar-homepage-weddings-link">Inicio</a>
                    <a href="https://mibodamx.cloud/templateshome" className="navbar-homepage-weddings-link">Diseños</a>
                    <a href="https://admin.mibodamx.cloud" className="navbar-homepage-weddings-link">Iniciar Sesión</a>
                    <a href="https://admin.mibodamx.cloud/register" className="navbar-homepage-weddings-link">Registrarme</a>
                </div>

                <div className={`sidebar-homepage-weddings ${sidebarOpen ? "open" : ""}`} id="sidebar">
                    <a href="https://mibodamx.cloud" className="sidebar-link">Inicio</a>
                    <a href="https://mibodamx.cloud/templateshome" className="sidebar-link">Diseños</a>
                    <a href="https://admin.mibodamx.cloud" className="sidebar-link">Iniciar Sesión</a>
                    <a href="https://admin.mibodamx.cloud/register" className="sidebar-link">Registrarme</a>
                </div>
            </div>
            <div className="body-login-wrapper">
                <div className="login-form-container">
                    <img src="logo.png" className='login-logo' alt="" />
                    <p className="login-form-subtitle">Hola ! Ingresa tus datos para acceder a la plataforma</p>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="username"
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Usuario"
                            className="login-form-input"
                            required
                        />
                        <input
                            type="password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Contraseña"
                            className="login-form-input"
                            required
                        />

                        <p  className="login-form-problems">Problemas al iniciar sesión?</p>

                        <p onClick={() => navigate('/register')}className="login-form-problems">Registrarme</p>

                        <input
                            type="submit"
                            value="Iniciar sesion"
                            className="login-form-button"
                        />
                    </form>
                    
                </div>
            </div>

        </div>
    );
};

export default Login;
