import { useState } from 'react';
import axios from 'axios';
import '../Login/login.css';
import { API_URL } from '../../helpers/api';
import { useToast } from '../../components/Native/Toast/ToastContext';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const addToast = useToast();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [nombres, setNombres] = useState('');
  const [email, setEmail] = useState('');
  // En el estado solo guardamos la parte final de la URL, SIN el "https://mibodamx.cloud/"
  const [url, setUrl] = useState('');
  const [template, setTemplate] = useState('1');
  const [userType, setUserType] = useState('0');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
      setSidebarOpen(!sidebarOpen);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Cuando enviemos los datos, concatenamos
    const data = JSON.stringify({
      user: username,
      url: url,
      template,
      type: userType,
      email,
      nombres,
      password
    });

    axios
      .post(`${API_URL}newUser`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        addToast(`Usuario creado !`, 'success');

        setTimeout(() => {
          window.location.href = '/login';
        } , 4000);

        //addToast(`Usuario creado !: ${JSON.stringify(response.data)}`, 'success');
      })
      .catch((error) => {
        let errorMessage = 'Error desconocido';
        if (error.response) {
          errorMessage = error.response.data.message;
        } else if (error.request) {
          errorMessage = 'No se pudo conectar al servidor';
        } else {
          errorMessage = error.message;
        }
        addToast(`Error: ${errorMessage}`, 'error');
      });
  };

  return (
    <div className="body-login">
      <div className="navbar-homepage-weddings">
          <div className="navbar-homepage-weddings-logo">
              <img
                  src="/transparent_logo.png"
                  alt="logo"
                  width={100}
                  className="navbar-homepage-weddings-logo-img"
              />
          </div>

          <button className="navbar-hamburger" onClick={toggleSidebar}>
              {sidebarOpen ? "✖" : "☰"}
          </button>

          <div className="navbar-homepage-weddings-links">
              <a href="https://mibodamx.cloud" className="navbar-homepage-weddings-link">Inicio</a>
              <a href="https://mibodamx.cloud/templateshome" className="navbar-homepage-weddings-link">Diseños</a>
              <a href="https://admin.mibodamx.cloud" className="navbar-homepage-weddings-link">Iniciar Sesión</a>
              <a href="https://admin.mibodamx.cloud/register" className="navbar-homepage-weddings-link">Registrarme</a>
          </div>

          <div className={`sidebar-homepage-weddings ${sidebarOpen ? "open" : ""}`} id="sidebar">
              <a href="https://mibodamx.cloud" className="sidebar-link">Inicio</a>
              <a href="https://mibodamx.cloud/templateshome" className="sidebar-link">Diseños</a>
              <a href="https://admin.mibodamx.cloud" className="sidebar-link">Iniciar Sesión</a>
              <a href="https://admin.mibodamx.cloud/register" className="sidebar-link">Registrarme</a>
          </div>
      </div>
      <div className="body-login-wrapper">
        <div className="login-form-container">
          <img src="logo.png" className='login-logo' alt="" />
          <h1 className="login-form-title">Crear una cuenta</h1>
          <form onSubmit={handleSubmit}>

            <input
              type="text"
              name="nombres"
              onChange={(e) => setNombres(e.target.value)}
              placeholder="Nombre y apellidos"
              className="login-form-input"
              required
            />
            <input
              type="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="login-form-input"
              required
            />
            
            {/* Etiqueta para explicar cómo quedará la URL */}
            <label htmlFor="url" className="custom-label-register">
              Esta será tu link de invitación:
            </label>
            
            {/* Contenedor para mostrar el prefijo y el input en una sola línea (opcional) */}
            <div className='register-prefix-box'>
              <span className='register-prefix-span' >
                https://mibodamx.cloud/
              </span>
              <input
                type="text"
                name="url"
                style={{ marginLeft: '8px' }}
                value={url}
                onChange={(e) => {
                  // Si el usuario llegara a pegar el prefijo completo,
                  // lo removemos para quedarnos únicamente con la parte final.
                  let newUrl = e.target.value.replace(/^https:\/\/mibodamx\.cloud\//, '');
                  // Por si alguien pega la barra adicional al final
                  newUrl = newUrl.replace(/^\//, '');
                  setUrl(newUrl);
                }}
                placeholder="rodrigoymarta"
                className="login-form-input"
                required
              />
            </div>

            <input
              type="text"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Usuario"
              className="login-form-input"
              required
            />

            <input
              type="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Contraseña"
              className="login-form-input"
              required
            />

            <input type="submit" value="Crear usuario" className="login-form-button" />
            <p onClick={() => navigate('/login')} className="login-form-problems">
              Iniciar Sesión
            </p>
          </form>
        </div>
      </div>


    </div>
  );
};

export default Register;
