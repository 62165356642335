import { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Section from "../../components/Native/Template/Template";
import { AppDispatch, fetchContent } from "../../store/actions/dataSlice";
import axios from "axios";
import { API_URL } from "../../helpers/api";
import "./site.css";

export function Site() {
  const dispatch = useDispatch<AppDispatch>();
  const [paid, setPaid] = useState<string | null>(null);
  const [siteFound, setSiteFound] = useState<boolean>(false); 
  const [loading, setLoading] = useState<boolean>(true); 


  
  // También podrías recuperar userType como antes, si lo necesitas
  const userType = Number(localStorage.getItem("userType"));

  // Función para obtener idBoda e información de pago
  const getIdBoda = async () => {
    const { hostname, pathname } = window.location;
    let eventDomain = hostname.startsWith("www.")
      ? hostname.slice(4)
      : hostname;

    // Si tu dominio principal es "mibodamx.cloud", tomamos el pathname
    if (eventDomain === "mibodamx.cloud") {
      eventDomain = pathname.replace(/^\/|\/$/g, "");
    }

    try {
      const res = await axios.get(`${API_URL}getIdBoda`, {
        params: {
          url: eventDomain,
        },
      });

      const idBoda = res.data.idBoda;
      const idSitio = res.data.idSitio;
      const paidValue = res.data.paid; // "0" o "1"

      // Guardar en localStorage si lo deseas
      localStorage.setItem("idBoda", idBoda);
      localStorage.setItem("idSitio", idSitio);
      // Guardamos el valor de paid en el estado
      setPaid(paidValue);

      // Cargar el contenido solo si existe idBoda
      if (idBoda) {
        setSiteFound(true);
        dispatch(fetchContent());

      }
      setLoading(false);
    } catch (error) {
      setSiteFound(false);
      console.error("Error al obtener idBoda:", error);
      setLoading(false);
    }

  };

  // Llamar a getIdBoda cuando el componente se monte
  useEffect(() => {
    getIdBoda();
  }, []);

  // Obtenemos el contenido desde el store
  const sections = useSelector((state: { content: any }) => state.content);

  // 1. Mientras esperamos la respuesta de getIdBoda, paid será null
  //    Podemos mostrar un "Cargando..." o un simple <div></div>

  if (!siteFound && !loading) {
    return <div  style={{ textAlign: "center", marginTop: "3rem" }}>Boda no encontrada.</div>;
  }

  if (loading) {
    return <div  style={{ textAlign: "center", marginTop: "3rem" }} >Cargando sitio...</div>;
  }

  // 2. Si paid es "0", no mostramos el contenido
  if (paid === "0") {
    return (
      <div style={{ textAlign: "center", marginTop: "3rem" }}>
        <h2>Este sitio no está disponible.</h2>
        <p>Realiza el pago correspondiente.</p>
      </div>
    );
  }

  // 3. Si paid es "1", mostramos el contenido real
  return (
    <div>
      {sections?.contents?.body?.sections &&
        Object.values(sections.contents.body.sections).map((section: any) => {
          const componentType = section.name;
          const meta = {
            ...section.meta,
            idSection: section.id,
          };

          // Cargar dinámicamente el componente
          let Component = lazy(
            () => import(`../../components/Templates/Description1/Description1`)
          );
          if (componentType) {
            Component = lazy(() =>
              import(
                `../../components/${
                  userType === 1 ? "Templates_events" : "Templates"
                }/${componentType}/${componentType}`
              )
            );
          }

          return (
            <div key={section.id}>
              <Suspense fallback={<div>Cargando...</div>}>
                <Section meta={meta} id={section.id} builder={false} section>
                  <Component meta={meta} builder={false} />
                </Section>
              </Suspense>
            </div>
          );
        })}

      {/* Footer */}
      <div className="site-footer">
        <p>
          © <span className="year"></span> Todos los derechos reservados | Creado
          con ❤️ por
          <a href="https://mibodamx.cloud" target="_blank" className="footer-link">
            {" "}
            mibodamx.cloud
          </a>
        </p>
        <p>
          ¿Quieres la tuya? Síguenos:
          <a
            href="https://www.instagram.com/mibodamx.cloud/"
            target="_blank"
            className="footer-link social-instagram"
          >
            {" "}
            Instagram
          </a>
        </p>
      </div>
    </div>
  );
}
