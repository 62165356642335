import { useCookies } from 'react-cookie';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import NavbarBuilder from '../components/builder/NavbarBuilder/NavbarBuilder';
import Sidebar from '../components/builder/Sidebar/Sidebar';
import './privateRoutes.css';

const ProtectedNavigation = () => {
  const [cookies] = useCookies(['token']);
  const [loading, setLoading] = useState(true); // Estado inicial en true para mostrar loader
  const location = useLocation(); // Detectar cambios de ruta

  const localStorage = window.localStorage;
  const idBoda = localStorage.getItem("idBoda");
  const isAuthenticated = cookies.token && idBoda != null;

  const isBuilderOrViewer =
    location.pathname.includes('/builder') || location.pathname.includes('/viewer');

  // Función para simular la carga del contenido (puede ser una API o un recurso externo)
  const loadContent = async () => {
    setLoading(true); // Mostrar loader mientras se carga el contenido
    try {
      // Simular carga (reemplazar con lógica real de carga si es necesario)
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Espera 1 segundo
    } finally {
      setLoading(false); // Ocultar loader una vez cargado
    }
  };

  useEffect(() => {
    loadContent(); // Ejecutar cada vez que cambie la ruta
  }, [location]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Mostrar solo el loader si está cargando
  if (loading) {
     // Cambia este mensaje por tu componente Loader si es necesario
  }

  // Mostrar el contenido normal si no está cargando
  return (
    <div className="content-wrapper">

      <Sidebar />
      <div className="builder-wrapper">
        {isBuilderOrViewer && <NavbarBuilder />}
        <Outlet />
      </div>
    </div>
  );
};

const PrivateRoutes = () => {
  return <ProtectedNavigation />;
};

export default PrivateRoutes;
