import React, { useState } from 'react';
import './HomePage.css';

const HomePage = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div className="container-homepage-weddings">
        <div className="navbar-homepage-weddings">
            <div className="navbar-homepage-weddings-logo">
                <img
                    src="/transparent_logo.png"
                    alt="logo"
                    width={100}
                    className="navbar-homepage-weddings-logo-img"
                />
            </div>

            <button className="navbar-hamburger" onClick={toggleSidebar}>
                {sidebarOpen ? "✖" : "☰"}
            </button>

            <div className="navbar-homepage-weddings-links">
                <a href="https://mibodamx.cloud" className="navbar-homepage-weddings-link">Inicio</a>
                <a href="/templateshome" className="navbar-homepage-weddings-link">Diseños</a>
                <a href="https://admin.mibodamx.cloud" className="navbar-homepage-weddings-link">Iniciar Sesión</a>
                <a href="https://admin.mibodamx.cloud/register" className="navbar-homepage-weddings-link">Registrarme</a>
            </div>

            <div className={`sidebar-homepage-weddings ${sidebarOpen ? "open" : ""}`} id="sidebar">
                <a href="https://mibodamx.cloud" className="sidebar-link">Inicio</a>
                <a href="https://mibodamx.cloud/templateshome" className="sidebar-link">Diseños</a>
                <a href="https://admin.mibodamx.cloud" className="sidebar-link">Iniciar Sesión</a>
                <a href="https://admin.mibodamx.cloud/register" className="sidebar-link">Registrarme</a>
            </div>
        </div>


            <div className="hero-homepage-weddings">
                <div className="hero-homepage-weddings-left">
                    <h1 className='hero-homepage-weddings-title'>Tu boda, tus reglas: <br /> Invitaciones digitales personalizadas al alcance de un clic</h1>

                    <p className='hero-homepage-weddings-subtitle .text-homepage'>Crea invitaciones únicas, gestiona tus invitados y haz de tu boda un evento inolvidable.</p>

                    <div className="hero-homepage-weddings-buttons">
                        <a href="https://admin.mibodamx.cloud/register" className="hero-homepage-weddings-button">Crear mi invitacion ahora</a>
                        <a href="https://mibodamx.cloud/templateshome" className="hero-homepage-weddings-button">Ver diseños</a>
                    </div>

                    <h2 className='hero-homepage-weddings-sub-subtitle'>¿Qué es?</h2>
                    <p className='hero-homepage-weddings-footer-subtitle text-homepage'>La nueva forma de invitar a tus seres queridos.</p>

                    <p className='hero-homepage-weddings-footer-text '>Nuestro sistema de invitaciones de boda personalizadas te permite diseñar, enviar y gestionar invitaciones digitales de manera sencilla y elegante. ¡Despídete de las invitaciones en papel y dale la bienvenida a la innovación!</p>
                </div>
                <div className="hero-homepage-weddings-right">
                    <div className="hero-homepage-weddings-right-photos">
                        <img src="phone_2.jpeg" alt="Imagen 1" className="hero-homepage-weddings-right-photos__image " />
                        <img src="phone_1.jpeg" alt="Imagen 2" className="hero-homepage-weddings-right-photos__image" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
