import { useState } from 'react';
import './profile.css';
import { API_URL } from '../../helpers/api';
import CheckoutConekta from '../../helpers/checkoutConekta';
import { useToast } from '../../components/Native/Toast/ToastContext';

const Profile = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [email, setEmail] = useState<string>(localStorage.getItem('email') || '');
    const [receiveConfirmations, setReceiveConfirmations] = useState(localStorage.getItem('sendEmail') === 'true');

    const [showPassword, setShowPassword] = useState(false);

    // Obtener datos de localStorage
    const idBoda = localStorage.getItem('idBoda');
    const urlmiboda = localStorage.getItem('urlmiboda') || '';
    const paid = localStorage.getItem('turing_mibodamx') === '1';

    const addToast = useToast();

    const handlePasswordChange = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_URL}changePassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idBoda,
                    oldPassword,
                    newPassword,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al cambiar la contraseña');
            }

            const data = await response.json();
            addToast('Contraseña cambiada con éxito', 'success');
            setOldPassword('');
            setNewPassword('');
        } catch (error: any) {
            addToast('Error al cambiar la contraseña', 'error');
        }
    };

    const EmailChange = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();


        try {
            const response = await fetch(`${API_URL}changeEmail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idBoda,
                    email
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                addToast('Error al actualizar el correo', 'error');
                throw new Error(errorData.message || 'Error al actualizar el correo');
            }

            const data = await response.json();
            addToast('Correo actualizado con éxito', 'success');
            localStorage.setItem('email', email);
        } catch (error: any) {
            addToast('Error al actualizar el correo', 'error');
        }
    };

    const handleEmailChange = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();


        try {
            const response = await fetch(`${API_URL}changeEmail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idBoda,
                    email,
                    receiveConfirmations,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                addToast('Error al cambiar el correo electrónico', 'error');
                throw new Error(errorData.message || 'Error al cambiar el correo electrónico');
            }

            const data = await response.json();
            addToast(data.message || 'Correo electrónico cambiado con éxito', 'success');
            setEmail('');
        } catch (error: any) {
            addToast('Error al cambiar el correo electrónico', 'error');
        }
    };

    const handleReceiveEmailChange = async (value: boolean) => {
        setReceiveConfirmations(value);

        try {
            const response = await fetch(`${API_URL}changeReceiveEmail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    receiveConfirmations: value,
                    idBoda
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                addToast('Error al actualizar las notificaciones por correo electrónico', 'error');
                throw new Error(errorData.message || 'Error al actualizar las notificaciones por correo electrónico');
            }

            const data = await response.json();
            addToast( 'Notificaciones por email actualizadas', 'success');
            localStorage.setItem('sendEmail', String(value));
        } catch (error: any) {
            addToast('Error al actualizar las notificaciones por correo electrónico', 'error');
        }
    };

    const handlePayment = () => {
        //window.open("https://mibodamx.cloud/"+urlmiboda, "_blank")
        addToast('Ponte en contacto con nosotros para realizar el pago.', 'warning');
    }

    return (
        <>
            <div className='profile-container'>
                <p className='profile-title'>Perfil</p>

                {/* Sección para cambiar contraseña */}
                <div className="profile-form">
                    <form onSubmit={handlePasswordChange}>
                        <input
                            type="text"
                            placeholder='Antigua contraseña'
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                        />
                        <div className="password-input-container">
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder='Nueva contraseña'
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                            <button
                                type="button"
                                className="toggle-password-button"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                            </button>
                        </div>
                        <button type="submit" className='change-password-button'>Cambiar contraseña</button>
                    </form>
                </div>

                {/* Sección para actualizar correo */}
                <div className="profile-form">
                    <form onSubmit={EmailChange}>
                        <input
                            type="text"
                            placeholder='Correo Electrónico'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button type="submit" className='change-password-button'>Cambiar Correo Electrónico</button>
                    </form>
                </div>

                {/* Sección para la URL y el botón de pago */}
                <div className="profile-form">
                    <h2 className="profile-subtitle">Funciones premium</h2>
                    <p>
                    { paid 
                        ? "Ahora puedes disfrutar de ver tu boda en línea y compartirlo con tus invitados." 
                        :  "Haz clic en el link para habilitar el sitio público y poder enviar invitaciones."
                     }
                        
                    </p>

                    <div className="checkbox-container-profile">
                        <label htmlFor="urlmiboda">URL de tu boda:</label>
                        <input
                            type="text"
                            id="urlmiboda"
                            value={"https://mibodamx.cloud/"+urlmiboda}
                            readOnly
                            className="url-input"
                            onClick={(e) => handlePayment()}
                        />
                    </div>

                   { /*  <div className="checkout-button-container">
                        <CheckoutConekta />
                    </div> */}
                </div>

                {/* Sección para cambiar notificaciones por correo */}
                <div className="profile-form">
                    <form onSubmit={handleEmailChange}>
                        <div className="checkbox-container-profile">
                            <input
                                type="checkbox"
                                id="receiveConfirmations"
                                checked={receiveConfirmations}
                                onChange={(e) => handleReceiveEmailChange(e.target.checked)}
                            />
                            <label htmlFor="receiveConfirmations">
                                Deseo seguir recibiendo confirmaciones de invitados por correo
                            </label>
                        </div>
                        {/* Si luego deseas un botón para esto, puedes descomentar:
                            <button type="submit" className='change-email-button'>Cambiar correo electrónico</button>
                        */}
                    </form>
                </div>


            </div>
        </>
    );
};

export default Profile;
