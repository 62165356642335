import './template5.css';

const Template5 = () => {
  return (
    <div className="template5-invite">
        {/* Imagen de textura de fondo */}
        <img
            src="leavesShadow.png" // Ruta desde la carpeta public
            alt="Background texture"
            className="background-image"
        />
        <div className="invitation-container">
            <h2 className="save-the-date">Save The Date</h2>
            <div className="image-container">
                <img
                src="/1.png" // Reemplaza con tu URL de imagen
                alt="Wedding Rings"
                className="invitation-image"
                />
            </div>
            <h1 className="couple-names">Ana & Carlos</h1>
            <p className="invitation-subtitle">¡Se casan!</p>
            <div className="invitation-details">
                <div className="detail">
                <span className="detail-title">Sábado</span>
                </div>
                <div className="detail">
                <span className="detail-title">20 SEP 2024</span>
                </div>
                <div className="detail">
                <span className="detail-title">2:00 p.m</span>
                </div>
            </div>
            <p className="location">
                Atlixcáyotl 2501, Reserva Territorial Atlixcáyotl, 72830 Heroica Puebla
                de Zaragoza, Pue.
            </p>
        </div>
    </div>
  );
};

export default Template5;
