import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './home.css';

const Home = () => {
  const navigate = useNavigate();
  const [greeting, setGreeting] = useState("Buenos días");
  let username = localStorage.getItem('weddingUser');

  useEffect(() => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) {
      setGreeting("Buenos días");
    } else if (hour >= 12 && hour < 18) {
      setGreeting("Buenas tardes");
    } else {
      setGreeting("Buenas noches");
    }
  }, []);

  return (
    <>
    <div className="admin-home-wrapper">

      <div className="admin-home">

        <div className="card">
          <div className="background-image">
            <img
              src="/builder.svg"
              alt=""
            />
          </div>
          <div className="body">
            <p className="title">Builder</p>
            <p className="description">Crea y personaliza tus diseños únicos de forma rápida y sencilla. ¡Empieza ahora!</p>
            <button onClick={() => navigate('/builder')}>
              Ir al builder 
            </button>
          </div>
        </div>
        <div className="card">
          <div className="background-image">
            <img
              src="/templates.svg"
              alt=""
            />
          </div>
          <div className="body">
            <p className="title">Plantillas</p>
            <p className="description">Explora nuestra colección de plantillas diseñadas profesionalmente. ¡Encuentra la perfecta para ti!</p>
            <button onClick={() => navigate('/templates')}>
              Ir a plantillas
            </button>
          </div>
        </div>
        <div className="card">
          <div className="background-image">
            <img
              src="/invitations.svg"
              alt=""
            />
          </div>
          <div className="body">
            <p className="title">Invitaciones</p>
            <p className="description">Envía invitaciones inolvidables a tus seres queridos con nuestras opciones personalizables.</p>
            <button onClick={() => navigate('/invitations')}>
              Ir a invitaciones
            </button>
          </div>
        </div>
      </div>
    </div>

    </>
  );
};

export default Home;
