import { useState } from 'react';
import axios from 'axios';
import '../Login/login.css';
import { API_URL } from '../../helpers/api';
import { useToast } from '../../components/Native/Toast/ToastContext';

const Bum = () => {
    const addToast = useToast();

    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [url, setUrl] = useState('');
    const [template, setTemplate] = useState('1');
    const [userType, setUserType] = useState('0');
    const [accessGranted, setAccessGranted] = useState(false); // Estado para verificar si la contraseña es correcta

    // Contraseña correcta para acceder
    const correctPassword = 'bobadilla'; // Cambia esto por una contraseña real o lógica de verificación

    const handlePasswordChange = (e: any) => {
        setPassword(e.target.value);
    };

    const handlePasswordSubmit = (e: any) => {
        e.preventDefault();
        if (password === correctPassword) {
            setAccessGranted(true); // Si la contraseña es correcta, mostrar el contenido
        } else {
            addToast('Contraseña incorrecta', 'error'); // Si la contraseña es incorrecta
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        let user = username;
        let urlData = url;

        const data = JSON.stringify({ user, url: urlData, template, type: userType, email });

        axios.post(`${API_URL}newUser`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            addToast(`usuario creado: ${JSON.stringify(response.data)}`, 'success');
        })
        .catch((error) => {
            let errorMessage = 'Error desconocido';
            if (error.response) {
                errorMessage = error.response.data.message;
                addToast(`Error: ${errorMessage}`, 'error');
            } else if (error.request) {
                errorMessage = 'No se pudo conectar al servidor';
                addToast(`Error: ${errorMessage}`, 'error');
            } else {
                errorMessage = error.message;
                addToast(`Error: ${errorMessage}`, 'error');
            }
        });
    };

    // Si el acceso no ha sido concedido, solo mostramos el input de contraseña
    if (!accessGranted) {
        return (
            <div className="body-login">
                <div className="login-form-container">
                    <h1 className="login-form-title">Acceso restringido</h1>
                    <form onSubmit={handlePasswordSubmit}>
                        <input
                            type="password"
                            placeholder="Introduce la contraseña"
                            value={password}
                            onChange={handlePasswordChange}
                            className="login-form-input"
                        />
                        <input
                            type="submit"
                            value="Entrar"
                            className="login-form-button"
                        />
                    </form>
                </div>
            </div>
        );
    }

    // Si la contraseña es correcta, mostramos el contenido principal
    return (
        <div className="body-login">
            <div className="login-form-container">
                <h1 className="login-form-title">Nuevo usuario</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="username"
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Usuario"
                        className="login-form-input"
                        required
                    />

                    <input
                        type="email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        className="login-form-input"
                        required
                    />

                    <label htmlFor="typeSelect">Tipo de usuario:</label>
                    <select id="typeSelect" name="typeSelect" className="login-form-input" onChange={(e) => setUserType(e.target.value)} required>
                        <option value="0">Boda</option>
                        <option value="1">Evento</option>
                    </select>
                    <input
                        type="text"
                        name="url"
                        onChange={(e) => setUrl(e.target.value)}
                        placeholder="Url de sitio:"
                        className="login-form-input"
                        required
                    />
                    <label htmlFor="templateSelect">Seleccione un template:</label>
                    <select id="templateSelect" name="templateSelect" className="login-form-input" onChange={(e) => setTemplate(e.target.value)} required>
                        <option value="1" selected>1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                    <input
                        type="submit"
                        value="Crear usuario"
                        className="login-form-button"
                    />
                </form>
            </div>
        </div>
    );
};

export default Bum;
